<script>
import { required, alphaNum } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import pdpaModal from "@/components/pdpa.vue";

import ax from '../../../helpers/restful/service';


export default {
  components: {
    pdpaModal,
  },
  page: {
    title: "เข้าสู่ระบบลงทะเบียนครูแพทย์แผนไทย",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      ...appConfig,
      username: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      isPDPAModalShow: true,
    };
  },
  validations: {
    username: {
      required,
      alphaNum,
    },
    password: {
      required,
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
    document.body.classList.add("authentication-bg");
    
  },
  methods: {
    async tryToLogIn() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log('form is invalid');
        return;
      }

      this.tryingToLogIn = true;
      this.authError = null;

      const resp = await ax.post("/login", { username: this.username, password: this.password }, {}).catch((e) => {
        console.error("error:", e);
        alert(e.msg);
        if (e.status >= 400 && e.status <= 409) return e;
        return false;
      });

      if (resp == null || resp == undefined || !resp) return false;
      if (resp.data == null || resp.data == undefined) return false;
      if (resp.status != 200) {
        alert(resp.msg);
        return false;
      }
      localStorage.setItem('auth.user', JSON.stringify(resp.data));
      const next_route = localStorage.getItem('auth.originRoute') || "/course-registed";
      if (this.$route.path !== next_route) {
        this.$router.push(
          {
            path: next_route == '' ? "/main" : next_route,
          }
        );
      }
    },
    pdpaCloseModal() {
      this.isPDPAModalShow = false;
    },
  },
};
</script>

<template>
  <div>
    <div class="mb-5"></div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-9 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <img class="my-3 img-fluid" src="@/assets/banner.gif" />
                </div>
                <div class="p-2 mt-4">
                  <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>

                  <div v-if="notification.message" :class="'alert ' + notification.type">
                    {{ notification.message }}
                  </div>

                  <div class="col-12 mt-3 text-center">
                    <img class="img-fluid img-thumbnail d-block w-100" src="@/assets/images/qr-profile.jpg" />
                    <p class="mt-2 text-dark">
                      กรุณาสแกน QR Code ด้วย โทรศัพท์มือถือ<br />
                      เพื่อรับรหัสผู้ใช้งาน และรหัสผ่าน <br />
                      หรือ <a href="https://liff.line.me/1654372485-ZNvdVevM/profile" target="_blank">คลิ๊กที่นี่
                        เพื่อรับรหัสฯ</a><br />
                      หากใช้งานในระบบโทรศัพท์มือถือ
                    </p>
                  </div>

                  <b-form @submit.prevent="tryToLogIn">
                    <b-form-group id="input-group-1" class="mb-3">
                      <b-form-input id="input-1" v-model="username" type="text"
                        placeholder="ยืนยันตัวตนด้วยรหัสผู้ใช้ VSXXXXXX"
                        :class="{ 'is-invalid': submitted && $v.username.$error }"></b-form-input>
                      <div v-if="submitted && $v.username.$error" class="invalid-feedback">
                        <span v-if="!$v.username.required">ต้องกรอกชื่อผู้ใช้งาน</span>
                      </div>
                    </b-form-group>

                    <b-form-group id="input-group-2" class="mb-3">
                      <b-form-input id="input-2" v-model="password" type="password"
                        placeholder="ยืนยันตัวตนด้วยรหัสผ่าน ที่ตั้งค่าในระบบ" :class="{
                          'is-invalid': submitted && $v.password.$error,
                        }"></b-form-input>
                      <div v-if="submitted && !$v.password.required" class="invalid-feedback">
                        ต้องกรอกรหัสผ่าน
                      </div>
                    </b-form-group>

                    <div class="mt-3 text-center d-block d-grid">
                      <b-button type="submit" variant="warning" class="w-sm">เข้าสู่ระบบ</b-button>
                    </div>
                  </b-form>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            <div class="mt-5 text-center">
              <small>
                ติดต่อสอบถาม <a href="https://lin.ee/p9ptQ6s" target="_blank">มูลนิธิรวมพัฒน์</a><br/>
                {{ new Date().getFullYear()}} © มูลนิธิรวมพัฒน์ <img src=""/>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pdpaModal :isShow="isPDPAModalShow" v-on:close="pdpaCloseModal"></pdpaModal>
  </div>
</template>

<style lang="scss" module></style>
